import React from 'react';
import logo from './name-logo.png'; 
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
            <div className="Header-content">
                    <img src={logo} className="App-logo" alt="logo" />
                   
                    <p className="coming-soon">Coming Soon </p>
                </div>
            </header>
            <section className="App-features">
        <div className="feature-card">
        <div className="video-container"> 
                <iframe src="https://www.youtube.com/embed/1MbzXQix6HQ" 
                    title="YouTube video player" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  >
                </iframe>
            </div>
      
        </div>
        
     
      </section>
        </div>
    );
}

export default App;

